import React, { useState, useEffect, useCallback, Fragment } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { stringify } from 'query-string';
import {
  List,
  TextField,
  ExportButton,
  downloadCSV,
  TopToolbar,
  useRedirect,
  // DateInput,
  //FunctionField,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jsonExport from 'jsonexport/dist';
import { format, startOfMonth, subMonths, lastDayOfMonth } from 'date-fns';
import { useSidebarOpen } from '../../hooks';
import { Datagrid, ToggleButton } from '../../components/common';
import { DOWNLOAD_URL } from '../../utils/config';
import { DownloadIcon, CustomButton } from '../../design';
import { useStyles } from './reports.styles';

function exporter({ fields, existingFields, renameFields, type }) {
  const fieldsForExport = fields.map(field => {
    const { id, ...rest } = field;
    const row = Object.keys(rest).reduce((acc, cur) => {
      if (existingFields.includes(cur)) {
        acc[cur] = rest[cur];
      }
      return acc;
    }, {});
    return row;
  });
  jsonExport(
    fieldsForExport,
    {
      headers: [...existingFields],
      rename: [...renameFields],
    },
    (err, csv) => {
      downloadCSV(
        csv,
        `client-review-${type}-${format(new Date(), 'MM/dd/yyyy')}`,
      );
    },
  );
}

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  type,
  setReportType,
  title,
  setDivision,
  classes,
  ...rest
}) => {
  const token = localStorage.getItem('token');
  const facilityId = useSelector(state => state.facility.id);
  return (
    <TopToolbar
      className={clsx(
        classes.listActionWrapper,
        classes.flexWrap,
        classes.tabsActionWrapper,
      )}
    >
      <div>
        <div style={{ display: 'flex', paddingBottom: 20 }}>
          <span className={classes.title} style={{ paddingRight: 20 }}>
            {`Client review ${type}`}
          </span>
          <div style={{ paddingTop: -5 }}>
            <ToggleButton
              setDivision={setDivision}
              className={classes.toggleWrapper}
              showBoth={false}
            />
          </div>
        </div>

        <FiltersView
          classes={classes}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>

      <div style={{ display: 'flex' }}>
        <CustomButton
          Icon={DownloadIcon}
          color='#1061A0'
          backgroundColor='#EFF4FB'
          label='Download PDF'
          type='button'
          variant='text'
          size='small'
          disabled={total === 0}
          onClick={() =>
            window.open(
              `${DOWNLOAD_URL}?${stringify({
                _facilityId: facilityId,
                token,
                report: 'client-details',
                _start: 0,
                _end: 1000,
                ...filterValues,
                ...permanentFilter,
              })}`,
              '_blank',
            )
          }
        />
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          exporter={exporter}
          className={classes.exportButton}
        />
      </div>
      <ReportTabs
        setReportType={setReportType}
        classes={classes}
        basePath={basePath}
      />
    </TopToolbar>
  );
};

const FiltersView = ({
  classes,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  ...props
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ marginRight: 25 }}>
        <DatePicker
          views={['year', 'month']}
          label='From'
          openTo='month'
          value={startDate}
          onChange={setStartDate}
          required
          autoOk
          style={{ marginRight: 25, width: 171, color: '#5C738E' }}
          InputProps={{
            disableUnderline: true,
          }}
          margin='dense'
        />
        <DatePicker
          margin='dense'
          views={['year', 'month']}
          label='To'
          value={endDate}
          openTo='month'
          onChange={setEndDate}
          minDate={startDate}
          minDateMessage='End date should not be before start date'
          required
          autoOk
          style={{ width: 171, color: '#5C738E' }}
          InputProps={{
            disableUnderline: true,
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

const ReportList = ({
  type,
  setExistingFields,
  setRenameFields,
  existingFields,
  ...props
}) => {
  const isSidebarOpen = useSidebarOpen();
  const classes = useStyles({ isSidebarOpen });
  const { data = {}, ids } = props;

  useEffect(() => {
    const allFields = reportFields(type);
    const fields = [];
    const rename = [];
    // check if data is populated

    if (data) {
      ids.forEach(id => {
        Object.keys(data[id]).forEach(field => {
          if (data[id][field]) {
            fields.push(field);
          }
        });
      });
      const sortedFields = Object.keys(allFields).reduce((acc, cur) => {
        if (fields.includes(cur)) {
          acc.push(cur);
          rename.push(allFields[cur]);
        }
        return acc;
      }, []);
      setExistingFields(sortedFields);
      setRenameFields(rename);
    }
  }, [data, ids, setExistingFields, setRenameFields, type]);
  if (!existingFields) return null;

  return (
    <Fragment>
      <ReportListView
        classes={classes}
        existingFields={existingFields}
        {...props}
      />
    </Fragment>
  );
};

const ReportListView = ({ classes, existingFields = [], ...props }) => {
  return (
    <div className={classes.listContainer}>
      <Datagrid {...props} className={classes.listContainer}>
        {existingFields.includes('month') && (
          <TextField
            source='month'
            label='Month'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
            sortBy='last_name'
          />
        )}
        {existingFields.includes('payer') && (
          <TextField
            source='payer'
            label='Insurance'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('total') && (
          <TextField
            source='total'
            label='Total'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('revenue') && (
          <TextField
            source='revenue'
            label='Revenue'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('CustodialRoomBoard') && (
          <TextField
            source='CustodialRoomBoard'
            label='Custodial room board'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('CoInsurance') && (
          <TextField
            source='CoInsurance'
            label='Coinsurance'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('HospitalBedhold') && (
          <TextField
            source='HospitalBedhold'
            label='Hospital bedhold'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('TherapeuticLeave') && (
          <TextField
            source='TherapeuticLeave'
            label='Therapeutic leave'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('Level1') && (
          <TextField
            source='Level1'
            label='Level 1'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('Level2') && (
          <TextField
            source='Level2'
            label='Level 2'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('Level3') && (
          <TextField
            source='Level3'
            label='Level 3'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('Level4') && (
          <TextField
            source='Level4'
            label='Level 4'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('Level5') && (
          <TextField
            source='Level5'
            label='Level 5'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('Level6') && (
          <TextField
            source='Level6'
            label='Level 6'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('MDCRrate') && (
          <TextField
            source='MDCRrate'
            label='MDCR rate'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('SCArate') && (
          <TextField
            source='SCArate'
            label='SCA rate'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('FlatRate') && (
          <TextField
            source='FlatRate'
            label='Flat rate'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('ALOC') && (
          <TextField
            source='ALOC'
            label='ALOC'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('Vent') && (
          <TextField
            source='Vent'
            label='Vent'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('Pharmacy') && (
          <TextField
            source='Pharmacy'
            label='Pharmacy'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('UpdatesOnlyCoInsurance') && (
          <TextField
            source='UpdatesOnlyCoInsurance'
            label='Updates only coinsurance'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('CoInsuranceVent') && (
          <TextField
            source='CoInsuranceVent'
            label='Coinsurance vent'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('PartBOutpatient') && (
          <TextField
            source='PartBOutpatient'
            label='Part B outpatient'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('MedicaidRate') && (
          <TextField
            source='MedicaidRate'
            label='Medicaid rate'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('Pending') && (
          <TextField
            source='Pending'
            label='Pending'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}

        {existingFields.includes('AidToContinue') && (
          <TextField
            source='AidToContinue'
            label='Aid to continue'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('NoUpdatesCoInsurance') && (
          <TextField
            source='NoUpdatesCoInsurance'
            label='No updates / Coinsurance'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('SkilledNursingLOC') && (
          <TextField
            source='SkilledNursingLOC'
            label='Skilled nursing LOC'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('SubacuteLOC') && (
          <TextField
            source='SubacuteLOC'
            label='Subacute LOC'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('AcuteLOC') && (
          <TextField
            source='AcuteLOC'
            label='Acute LOC'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('patients') && (
          <TextField
            source='patients'
            label='Patients'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('discharges') && (
          <TextField
            source='discharges'
            label='Discharges'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('dischargeDays') && (
          <TextField
            source='dischargeDays'
            label='Discharge days'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('averageLos') && (
          <TextField
            source='averageLos'
            label='Average LOS'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
        {existingFields.includes('averageDailyRate') && (
          <TextField
            source='averageDailyRate'
            label='Average daily rate'
            sortable={false}
            headerClassName={classes.noLineBreak}
            className={classes.noLineBreak}
          />
        )}
      </Datagrid>
    </div>
  );
};

export const ClientReviewReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const { basePath } = props;
  const redirect = useRedirect();
  const [division, setDivision] = useState('subacute');
  const [existingFields, setExistingFields] = useState();
  const [renameFields, setRenameFields] = useState();
  const [reportType] = useState(
    basePath === `/client-details-report/report` ? 0 : 1,
  );
  const [startDate, setStartDate] = useState(
    startOfMonth(subMonths(new Date(), 1)),
  );
  const [endDate, setEndDate] = useState(
    lastDayOfMonth(subMonths(new Date(), 1)),
  );

  const setReportType = value => {
    if (value === reportType) return;
    const path =
      value === 0
        ? 'client-details-report/report'
        : 'client-details-report/summary';
    redirect(`/${path}`);
  };

  const type = reportType === 0 ? 'report' : 'summary';

  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={
          <ListActions
            setDivision={setDivision}
            // filter={filter}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            type={type}
            setReportType={setReportType}
            existingFields={existingFields}
            renameFields={renameFields}
            classes={classes}
          />
        }
        filter={{
          division,
          start_date: format(startDate, 'yyyy-MM-dd'),
          end_date: format(endDate, 'yyyy-MM-dd'),
        }}
        sort={{ field: 'last_name', order: 'ASC' }}
        exporter={fields =>
          exporter({ fields, existingFields, renameFields, type })
        }
      >
        <ReportList
          setExistingFields={setExistingFields}
          setRenameFields={setRenameFields}
          existingFields={existingFields}
          type={type}
        />
      </List>
    </Fragment>
  );
};

export const clientReviewReportResource = {
  name: 'reports/client-review-report',
  list: ClientReviewReportList,
};

function getResourceProps(props) {
  const { location: { pathname } = {} } = props;
  const basePath = pathname;
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource:
          basePath === '/client-details-report/report'
            ? 'reports/client-review-report'
            : 'reports/client-review-summary',
        hasList: true,
        hasEdit: false,
        hasShow: false,
        hasCreate: false,
      };
  return { ...props, basePath, ...resource };
}

const ReportTabs = ({ setReportType, classes, basePath }) => {
  const [value, setValue] = useState(
    basePath === `/client-details-report/report` ? 0 : 1,
  );
  const handleChange = useCallback(
    (event, newValue) => {
      setValue(newValue);
      setReportType(newValue);
    },
    [setReportType],
  );
  return (
    <Paper className={classes.tabContainer} square>
      <Tabs
        value={value}
        indicatorColor='primary'
        textColor='inherit'
        onChange={handleChange}
      >
        <Tab
          label='Report'
          classes={{ root: classes.tab, selected: classes.selectedTab }}
        />
        <Tab
          label='Summary'
          classes={{ root: classes.tab, selected: classes.selectedTab }}
        />
      </Tabs>
    </Paper>
  );
};

const reportFields = type => {
  const fields = {
    month: 'Month',
    payer: 'Insurance',
    total: 'Total',
    revenue: 'Revenue',
    CustodialRoomBoard: 'Custodial Room Board',
    CoInsurance: 'Co-Insurance',
    HospitalBedhold: 'Hospital Bedhold',
    TherapeuticLeave: 'Therapeutic Leave',
    Level1: 'Level 1',
    Level2: 'Level 2',
    Level3: 'Level 3',
    Level4: 'Level 4',
    Level5: 'Level 5',
    MDCRrate: 'MDCR Rate',
    SCArate: 'SCA Rate',
    FlatRate: 'Flat Rate',
    ALOC: 'ALOC',
    Vent: 'Vent',
    Pharmacy: 'Pharmacy',
    UpdatesOnlyCoInsurance: 'Updates Only Co-Insurance',
    CoInsuranceVent: 'CoInsurance Vent',
    PartBOutpatient: 'Part B / Outpatient',
    MedicaidRate: 'Medicaid Rate',
    Level6: 'Level 6',
    Pending: 'Pending',
    AidToContinue: 'Aid To Continue',
    NoUpdatesCoInsurance: 'No Updates / Co-Insurance',
    SkilledNursingLOC: 'Skilled Nursing LOC',
    SubacuteLOC: 'Subacute LOC',
    AcuteLOC: 'Acute LOC',
  };
  if (type === 'summary') {
    const { payer, ...fieldsFromDetails } = fields;
    return {
      ...fieldsFromDetails,
      patients: 'Patients',
      discharges: 'Discharges',
      dischargeDays: 'Discharge Days',
      averageLos: 'Average Los',
      averageDailyRate: 'Average Daily Rate',
    };
  }
  return fields;
};
