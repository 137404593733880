import React, { useState, Fragment, useEffect } from 'react';

import { Form } from 'react-final-form';
import { useRefresh, useNotify, required } from 'react-admin';
import { DialogActions, DialogContent } from '@material-ui/core';
import { useStyles } from '../modal.styles';
import {
  handleNumbers,
  getDateInputValue,
  ReferenceInput,
  FormTextField,
  composeValidators,
  validatePresetName,
} from '.';
import { SaveButton } from '../../../../design';
import { authGet } from '../../../../server';
export const PresetSettingsForm = ({ handleClose, params, isEdit }) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const notify = useNotify();
  const {
    reportId,
    reportType,
    facilityIds,
    groupIds,
    userIds,
    division,
    presetId,
    grid,
    presetName,
    saveNew,
    bulkLevel,
  } = params;
  const [formData, setFormData] = useState({
    roles: [],
    users: [],
    name: isEdit && !saveNew ? presetName : '',
  });
  useEffect(() => {
    const getRoles = async () => {
      const response = await authGet([
        `/preset-roles`,
        { preset_id: presetId },
      ]);
      const { data } = response;
      if (data) {
        const { roles, users } = data;
        setFormData(f => ({ ...f, roles: roles || [], users: users || [] }));
      }
    };
    if (isEdit) getRoles();
  }, [presetId, isEdit]);

  const onSubmit = value => {
    if (isEdit && !saveNew) {
      grid.SetSession(
        `name=${value.name};report_id=${reportId};report_type=${reportType};facility_ids=${facilityIds};group_ids=${groupIds};user_ids=${userIds};division=${division};roles=${value.roles};users=${value.users};bulk=${bulkLevel};preset_id=${presetId}`,
      );
    } else {
      grid.SetSession(
        `name=${value.name};report_id=${reportId};report_type=${reportType};facility_ids=${facilityIds};group_ids=${groupIds};user_ids=${userIds};division=${division};roles=${value.roles};users=${value.users};bulk=${bulkLevel}`,
      );
    }
    grid.Save();
    window.Grids.OnAfterSave = function(grid, result) {
      if (result < 0) notify(`Error. Could not save report.`);
      else {
        notify(`${value.name} saved.`);
        handleClose();
        refresh();
      }
    };
  };

  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }
    if (name === 'roles') {
      setFormData(f => ({ ...f, users: [], [name]: value }));
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <FormTextField
                  customOnChange={customOnChange}
                  variant='outlined'
                  size='small'
                  label='Report name'
                  fullWidth
                  name='name'
                  required
                  validate={composeValidators([
                    required(),
                    validatePresetName(),
                  ])}
                />
                <ReferenceInput
                  key={userIds}
                  reference='roles/list'
                  customOnChange={customOnChange}
                  name='roles'
                  label='Roles'
                  required
                  validate={required()}
                  fullWidth
                  multiple
                  setFormData={setFormData}
                  selectAll
                  options={{
                    filter: {
                      admin_only: !!userIds?.length,
                    },
                  }}
                />
                <ReferenceInput
                  key={formData.roles}
                  customOnChange={customOnChange}
                  label='Users'
                  name='users'
                  reference='facility-users/list'
                  fullWidth
                  perPage={500}
                  multiple
                  options={{
                    filter: {
                      reports: true,
                      roles: formData.roles,
                      all_facilities: true,
                      create: true,
                    },
                  }}
                  setFormData={setFormData}
                  selectAll
                />
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                <SaveButton
                  // onClick={handleClose}
                  className={classes.saveButton}
                  disabled={submitting}
                  type='submit'
                />
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};
