import React, { Fragment, useState, useEffect } from 'react';
import {
  useRefresh,
  ListView,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  FunctionField,

  // NumberField,
  // ChipField,
  TopToolbar,
  Pagination,
  // ReferenceField,
  // ShowGuesser,
  usePermissions,
  useListController,
  useUnselectAll,
} from 'react-admin';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link, Chip } from '@material-ui/core';
import { GeneralInfo } from './general-info';
import { PayerContacts } from './payer-contacts';
import { QualifyingCriteria } from './qualifying-criteria';
import { Rates } from './rates';
import { Outliers } from './outliers';
import { PayerIntegrationDetails } from './payer-integration-details';
import { Datagrid } from '../../components/common';
import { NewEvent, Attachments } from '../../components/common/modals';
import { AddAttachmentModal } from '../../components/common/modals/modalForms';
import { Loader } from '../../components/common/Loading';
import { CustomButton } from '../../design/material-ui';
import { constants } from '../../utils';
import { DOWNLOAD_URL } from '../../utils/config';
import { useStyles } from './payers.styles';
import { getChip } from '../../utils';
import {
  PayerIcon,
  AddIcon,
  EditIcon,
  AttachmentIcon,
  LaunchIcon,
} from '../../design';
import { TrackUser } from '../../components/wrappers';
import { ListSearch } from '../../components/common/ListSearch';
import { stringify } from 'query-string';
const token = localStorage.getItem('token');
const { eventTypes } = constants;

const ContractsActions = ({ setSearchText }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isAdminOrSupervisor =
    userPermissions.indexOf('admin') > -1 ||
    userPermissions.indexOf('supervisor') > -1;
  const isContractUser =
    Array.isArray(userPermissions) &&
    userPermissions.indexOf('contract_user') > -1;

  const [eventOpen, setEventModal] = useState(false);

  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <span className={classes.title}>Payer contracts</span>

        <div style={{ display: 'flex' }}>
          <ListSearch
            doSearch={setSearchText}
            placeholder='Search payer name'
          />
          {isAdminOrSupervisor || isContractUser ? (
            <div style={{ marginTop: 10 }}>
              <CustomButton
                Icon={AddIcon}
                color='#1061A0'
                backgroundColor='#EFF4FB'
                label='Add'
                type='button'
                variant='text'
                size='small'
                onClick={() => setEventModal(true)}
              />
            </div>
          ) : (
            //for spacing...
            <div />
          )}
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          form='facilityPayer'
          refresh={refresh}
        />
      )}
    </Fragment>
  );
};

const ShowActions = ({
  basePath,
  data = {},
  id,
  title,
  resource,
  isViewAdmin,
}) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isAdminOrSupervisor =
    userPermissions.indexOf('admin') > -1 ||
    userPermissions.indexOf('supervisor') > -1;
  const isContractUser = userPermissions.indexOf('contract_user') > -1;
  const [eventOpen, setEventModal] = useState(false);
  const [cloneOpen, setCloneOpen] = useState(false);
  const [attchOpen, setAttchModal] = useState(false);
  const facilityId = useSelector(state => state.facility.id);
  const { payersFacilityDocs } = data;
  const hasAttachments =
    !!data && Array.isArray(payersFacilityDocs) && !!payersFacilityDocs.length;
  const document_ids = payersFacilityDocs
    ? payersFacilityDocs.map(document => document.document_id)
    : null;
  const documentCount = document_ids?.length;
  return (
    <Fragment>
      <TopToolbar className={classes.listActionWrapper}>
        <div className={classes.title}>
          Payer contract details
          <div
            className={classes.subtitle}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: 10,
            }}
          >
            Created by
            {'      '} {data.payersFacilityCreatedBy?.name} {'      '}| Last
            modified on <div style={{ width: 3 }} />
            <TrackUser timestamp={data.updated_at} />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          {isAdminOrSupervisor || isContractUser ? (
            <CustomButton
              Icon={EditIcon}
              color='#1061A0'
              backgroundColor='#EFF4FB'
              label='Edit'
              type='button'
              variant='text'
              size='small'
              onClick={() => setEventModal(true)}
            />
          ) : (
            // for spacing...
            <div />
          )}
          <div style={{ paddingRight: 3 }} />
          {isAdminOrSupervisor || isContractUser || isViewAdmin ? (
            <CustomButton
              Icon={AttachmentIcon}
              color='#1061A0'
              backgroundColor='#EFF4FB'
              label={hasAttachments ? 'View attachment' : 'Add attachment'}
              type='button'
              variant='text'
              size='small'
              onClick={() => setAttchModal(true)}
              badgeContent={documentCount}
              notRed
            />
          ) : (
            // for spacing...
            <div />
          )}
          <div style={{ paddingRight: 3 }} />
          {isAdminOrSupervisor || isContractUser ? (
            <FunctionField
              source=''
              label=''
              render={record => {
                const { rates, pharmacy_carve_outs } = record;
                return rates?.length || pharmacy_carve_outs?.length ? (
                  <CustomButton
                    Icon={AddIcon}
                    color='#1061A0'
                    backgroundColor='#EFF4FB'
                    label='Copy contract details'
                    type='button'
                    variant='text'
                    size='small'
                    onClick={() => setCloneOpen(true)}
                  />
                ) : null;
              }}
            />
          ) : (
            // for spacing...
            <div />
          )}
          <div style={{ paddingRight: 3 }} />
          <CustomButton
            Icon={LaunchIcon}
            color='#1061A0'
            backgroundColor='#EFF4FB'
            label='Cheat Sheet'
            type='button'
            variant='text'
            size='small'
            onClick={() =>
              window.open(
                `${DOWNLOAD_URL}?${stringify({
                  _facilityId: facilityId,
                  token,
                  payer_facility_id: id,
                  report: 'cheat-sheet',
                  _start: 0,
                  _end: 1000,
                })}`,
                '_blank',
              )
            }
          />
        </div>
      </TopToolbar>
      {eventOpen && (
        <NewEvent
          open={eventOpen}
          handleClose={() => setEventModal(false)}
          record={data}
          form='facilityPayer'
          isEdit
          refresh={refresh}
        />
      )}
      {cloneOpen && (
        <NewEvent
          open={cloneOpen}
          handleClose={() => setCloneOpen(false)}
          form='cloneContract'
          refresh={refresh}
          payerFacilityId={data.id}
          payerId={data.payer_id}
          meta={{ in_network: data.in_network }}
        />
      )}
      {attchOpen && (
        <Fragment>
          {hasAttachments ? (
            <Attachments
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              document_ids={document_ids}
              title='Payer'
              eventId={data.id}
              eventName={eventTypes.FACILITY_PAYER_NAME}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          ) : (
            <AddAttachmentModal
              open={attchOpen}
              handleClose={() => setAttchModal(false)}
              title='Payer'
              eventId={data.id}
              eventName={eventTypes.FACILITY_PAYER_NAME}
              refresh={refresh}
              isViewAdmin={isViewAdmin}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export const PayersList = props => {
  const { permissions = '' } = props;
  const userPermissions = permissions.split(',');
  const [searchText, setSearchText] = useState('');
  const isAdminOrSupervisor =
    userPermissions?.indexOf('admin') > -1 ||
    userPermissions?.indexOf('supervisor') > -1;
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  const classes = useStyles();
  const controllerProps = useListController({
    ...props,
    resource: 'payers-facility',
    sort: { field: 'Payers.name', order: 'ASC' },
    filter: { q: searchText },
  });
  const { loading } = controllerProps;
  const unselectAll = useUnselectAll('payers-facility');
  useEffect(() => {
    return () => {
      unselectAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListView
      empty={false}
      {...controllerProps}
      exporter={false}
      bulkActionButtons={
        Array.isArray(userPermissions) && isAdminOrSupervisor
          ? undefined // will show default delete
          : false // disable delete
      }
      className={classes.list}
      actions={
        <ContractsActions
          setSearchText={setSearchText}
          isViewAdmin={isViewAdmin}
        />
      }
      sort={{
        field: 'Payers.name',
        order: 'ASC',
      }}
      pagination={
        <Pagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          style={{
            display: 'flex',
          }}
        />
      }
    >
      {loading ? (
        <Loader open={loading} />
      ) : (
        <Datagrid rowClick='show'>
          <TextField
            source='Payers.name'
            label='Name'
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            source='is_active'
            label='Status'
            render={record => {
              const { is_active } = record;
              const status =
                !!is_active || is_active === null ? 'Active' : 'Inactive';
              return (
                <span>
                  <Chip
                    size='small'
                    label={status}
                    className={clsx(classes.chip, classes[getChip(status)])}
                  ></Chip>
                </span>
              );
            }}
          />
          <FunctionField
            source='line_of_business.payer_type'
            label='Products (LOB)'
            sortable={false}
            headerClassName={classes.listItemLabel}
            render={record => {
              const { line_of_businesses } = record;
              return Array.isArray(line_of_businesses)
                ? line_of_businesses.map(l => l.payer_type).join(', ')
                : null;
            }}
          />
          <TextField
            source='Payers.remit_address'
            label='Address'
            headerClassName={classes.listItemLabel}
          />
          <FunctionField
            source='in_network'
            label='Network'
            render={record => {
              const { in_network } = record;
              return !!in_network ? 'In network' : 'Out of network';
            }}
          />
          <FunctionField
            source='admit_notice_required'
            label='Admission notification required'
            render={record => {
              const { admit_notice_required } = record;
              return !!admit_notice_required ? 'Yes' : 'No';
            }}
          />
          <FunctionField
            source='by_navihealth'
            label='Managed by NaviHealth'
            render={record => {
              const { by_navihealth } = record;
              return !!by_navihealth ? 'Yes' : 'No';
            }}
          />
          <FunctionField
            label='Website'
            render={record => {
              const { Payers } = record;
              const website = Payers ? Payers.website : null;
              return website ? (
                <Link
                  href={website}
                  target='_blank'
                  rel='noopener noreferrer'
                  underline='none'
                >
                  {website}
                </Link>
              ) : null;
            }}
          />
          <FunctionField
            label='Attachments'
            render={record => {
              const { payersFacilityDocs } = record;
              return Array.isArray(payersFacilityDocs) &&
                payersFacilityDocs.length ? (
                <CustomButton
                  Icon={AttachmentIcon}
                  className={classes.attchListIcon}
                  variant='text'
                  badgeContent={payersFacilityDocs.length}
                  size='small'
                  notRed
                  fullSize
                />
              ) : null;
            }}
          />
        </Datagrid>
      )}
    </ListView>
  );
};

const PayersShow = props => {
  const { permissions = '' } = usePermissions();
  const userPermissions = permissions.split(',');
  const isViewAdmin =
    userPermissions?.indexOf('view_admin') > -1 ||
    userPermissions?.indexOf('admin_assistant') > -1;
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <Show
        actions={<ShowActions {...props} isViewAdmin={isViewAdmin} />}
        {...props}
      >
        <GeneralInfo />
      </Show>
      <Show {...props}>
        <TabbedShowLayout>
          <Tab
            classes={{ root: classes.tab, selected: classes.selectedTab }}
            label='Rates'
          >
            <Rates
              {...props}
              permissions={userPermissions}
              isViewAdmin={isViewAdmin}
            />
          </Tab>
          <Tab
            classes={{ root: classes.tab, selected: classes.selectedTab }}
            label='Inactive rates'
            path='inactive-rates'
          >
            <Rates
              {...props}
              permissions={userPermissions}
              isViewAdmin={isViewAdmin}
              inactive
            />
          </Tab>
          <Tab
            classes={{ root: classes.tab, selected: classes.selectedTab }}
            label='Qualifying criteria'
            path='qualifying-criteria'
          >
            <QualifyingCriteria
              {...props}
              permissions={userPermissions}
              isViewAdmin={isViewAdmin}
            />
          </Tab>
          <Tab
            classes={{ root: classes.tab, selected: classes.selectedTab }}
            label='Outliers'
            path='outliers'
          >
            <Outliers
              {...props}
              permissions={userPermissions}
              isViewAdmin={isViewAdmin}
            />
          </Tab>

          <Tab
            classes={{ root: classes.tab, selected: classes.selectedTab }}
            label='Integration details'
            path='integration-details'
          >
            <PayerIntegrationDetails
              {...props}
              permissions={userPermissions}
              isViewAdmin={isViewAdmin}
            />
          </Tab>
          <Tab
            classes={{ root: classes.tab, selected: classes.selectedTab }}
            label='Insurance case managers'
            path='insurance-case-managers'
          >
            <PayerContacts {...props} permissions={userPermissions} />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </Fragment>
  );
};

export const contractsResource = {
  name: 'payers-facility',
  list: PayersList,
  show: PayersShow,
  icon: PayerIcon,
  options: { label: 'Payer contracts' },
};
