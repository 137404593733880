import React, { useState, useEffect, Fragment } from 'react';
import { stringify } from 'query-string';
import { useSelector } from 'react-redux';
import {
  List,
  TextField,
  TopToolbar,
  Pagination,
  // TextInput,
  // ReferenceField,
  // ShowGuesser,
} from 'react-admin';
import { Datagrid, DateField } from '../../components/common';
import { NotesModal } from '../../components/common/modals';
import { DOWNLOAD_URL } from '../../utils/config';
import { useStyles } from './reports.styles';
import { DownloadIcon, CustomButton } from '../../design';

const ListActions = ({ filterValues = {}, total, classes }) => {
  const token = localStorage.getItem('token');
  const facilityId = useSelector(state => state.facility.id);
  return (
    <TopToolbar className={classes.listActionWrapper}>
      <span className={classes.title}>Payer contracts report</span>
      <CustomButton
        Icon={DownloadIcon}
        color='#1061A0'
        backgroundColor='#EFF4FB'
        label='Export'
        type='button'
        variant='text'
        size='small'
        disabled={total === 0}
        onClick={() =>
          window.open(
            `${DOWNLOAD_URL}?${stringify({
              _facilityId: facilityId,
              token,
              report: 'payer-rates',
              _start: 0,
              _end: 1000,
              ...filterValues,
            })}`,
            '_blank',
          )
        }
      />
    </TopToolbar>
  );
};

export const FacilityPayerReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const [showCarveOutsModal, setShowCarveOutsModal] = useState(false);
  const [selectedCarveOut, setSelectedCarveOut] = useState('');
  useEffect(() => {
    if (selectedCarveOut) {
      setShowCarveOutsModal(true);
    }
  }, [selectedCarveOut]);
  const handleCarveOutsModalClose = () => {
    setShowCarveOutsModal(false);
    setSelectedCarveOut('');
  };
  return (
    <Fragment>
      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={<ListActions classes={classes} />}
        exporter={false}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid>
          <TextField source='payer_name' label='Payer name' />
          <TextField source='line_of_businesses' label='LOB' sortable={false} />
          <DateField source='effective_date' label='Effective date' />
          <TextField source='rates' label='Rates' sortable={false} />
          <TextField
            source='pharmacy_carve_outs'
            label='Outliers'
            sortable={false}
          />
        </Datagrid>
      </List>
      {showCarveOutsModal && (
        <NotesModal
          open={showCarveOutsModal}
          handleClose={handleCarveOutsModalClose}
          note={selectedCarveOut}
          title='Carve outs'
        />
      )}
    </Fragment>
  );
};

export const facilityPayerResource = {
  name: 'reports/payer-rates-report',
  list: FacilityPayerReportList,
};

function getResourceProps(props) {
  const basePath = '/payers-report';
  const resource = {
    resource: 'reports/payer-rates-report',
    hasList: true,
    hasEdit: false,
    hasShow: !!facilityPayerResource.show,
    hasCreate: !!facilityPayerResource.create,
  };
  return { ...props, basePath, ...resource };
}
