const authFilters = [
  { id: '1', name: 'Approved' },
  { id: '2', name: 'Approved - Ended' },
  { id: '3', name: 'Denial received' },
  { id: '4', name: 'Denied' },
  { id: '5', name: 'Pending' },
  { id: '6', name: 'Missing' },
  { id: '7', name: 'Retro issue' },
  { id: 'no-attachments', name: 'No attachments' },
];

export const constants = {
  eventTypes: {
    ADMISSION_ID: 1,
    ADMISSION_NAME: 'Admission',
    AUTHORIZATION_ID: 2,
    AUTHORIZATION_NAME: 'Authorization',
    DENIAL_ID: 3,
    DENIAL_NAME: 'Denial',
    BEDHOLD_ID: 4,
    BEDHOLD_NAME: 'Bedhold',
    CASE_PAYER_ID: 5,
    CASE_PAYER_NAME: 'CasePayer',
    DISCHARGE_ID: 6,
    DISCHARGE_NAME: 'Discharge',
    FACILITY_PAYER_ID: 7,
    FACILITY_PAYER_NAME: 'FacilityPayer',
    PHARM_LOG_ID: 7,
    PHARM_LOG_NAME: 'PharmacyLog',
    APPEAL_ID: 8,
    APPEAL_NAME: 'Appeal',
    FACILITY_ID: 9,
    FACILITY_NAME: 'Facility',
    TASK_ID: 10,
    TASK_NAME: 'Task',
    COMMUNICATION_ID: 11,
    COMMUNICATION_NAME: 'Communication',
    MLTC_CASE_PAYER_NAME: 'MltcCasePayer',
  },
  testFacilities: {
    TESTING: 1,
    MOCK: 18,
    SANDBOX: 134,
  },
  bedholdStatusTypes: {
    IN_PROGRESS_ID: 1,
    IN_PROGRESS_NAME: 'In Progress',
    CLOSED_ID: 2,
    CLOSED_NAME: 'Closed',
  },
  updateTypes: [
    { id: 'OT', name: 'OT' },
    { id: 'PT', name: 'PT' },
    { id: 'SLP', name: 'SLP' },
    { id: 'Nursing', name: 'Nursing' },
    { id: 'Clinical Notes', name: 'Clinical notes' },
    { id: 'Wound Notes', name: 'Wound Notes' },
    { id: 'Physicians Orders', name: 'Physician’s orders' },
    { id: 'PRI', name: 'PRI' },
    { id: 'Medication List', name: 'Medication list' },
    { id: 'SNF Form', name: 'SNF Form' },
    { id: 'Rehab Minutes', name: 'Rehab minutes' },
    { id: 'Discharge Plan', name: 'Discharge plan' },
  ],
  authFilters,
  authReportFilters: authFilters.filter(f => f.id !== 'no-attachments'),
  faxLogsStatusFilters: [
    { id: 'SendingFailed', name: 'Sending failed' },
    { id: 'Resent', name: 'Resent' },
    { id: 'Awaiting Page count', name: 'Awaiting page count' },
    { id: 'Queued', name: 'Queued' },
    { id: 'Unknown', name: 'Unknown' },
  ],

  faxInboxFilters: [
    { id: 'Unassigned', name: 'Unassigned' },
    { id: 'Assigned', name: 'Assigned' },
    { id: 'Dismissed', name: 'Dismissed' },
  ],

  states: [
    {
      name: 'Alabama',
      id: 'AL',
    },
    {
      name: 'Alaska',
      id: 'AK',
    },
    {
      name: 'Arizona',
      id: 'AZ',
    },
    {
      name: 'Arkansas',
      id: 'AR',
    },
    {
      name: 'California',
      id: 'CA',
    },
    {
      name: 'Colorado',
      id: 'CO',
    },
    {
      name: 'Connecticut',
      id: 'CT',
    },
    {
      name: 'Delaware',
      id: 'DE',
    },
    {
      name: 'District of Columbia',
      id: 'DC',
    },
    {
      name: 'Florida',
      id: 'FL',
    },
    {
      name: 'Georgia',
      id: 'GA',
    },
    {
      name: 'Guam',
      id: 'GU',
    },
    {
      name: 'Hawaii',
      id: 'HI',
    },
    {
      name: 'Idaho',
      id: 'ID',
    },
    {
      name: 'Illinois',
      id: 'IL',
    },
    {
      name: 'Indiana',
      id: 'IN',
    },
    {
      name: 'Iowa',
      id: 'IA',
    },
    {
      name: 'Kansas',
      id: 'KS',
    },
    {
      name: 'Kentucky',
      id: 'KY',
    },
    {
      name: 'Louisiana',
      id: 'LA',
    },
    {
      name: 'Maine',
      id: 'ME',
    },
    {
      name: 'Maryland',
      id: 'MD',
    },
    {
      name: 'Massachusetts',
      id: 'MA',
    },
    {
      name: 'Michigan',
      id: 'MI',
    },
    {
      name: 'Minnesota',
      id: 'MN',
    },
    {
      name: 'Mississippi',
      id: 'MS',
    },
    {
      name: 'Missouri',
      id: 'MO',
    },
    {
      name: 'Montana',
      id: 'MT',
    },
    {
      name: 'Nebraska',
      id: 'NE',
    },
    {
      name: 'Nevada',
      id: 'NV',
    },
    {
      name: 'New Hampshire',
      id: 'NH',
    },
    {
      name: 'New Jersey',
      id: 'NJ',
    },
    {
      name: 'New Mexico',
      id: 'NM',
    },
    {
      name: 'New York',
      id: 'NY',
    },
    {
      name: 'North Carolina',
      id: 'NC',
    },
    {
      name: 'North Dakota',
      id: 'ND',
    },
    {
      name: 'Ohio',
      id: 'OH',
    },
    {
      name: 'Oklahoma',
      id: 'OK',
    },
    {
      name: 'Oregon',
      id: 'OR',
    },
    {
      name: 'Palau',
      id: 'PW',
    },
    {
      name: 'Pennsylvania',
      id: 'PA',
    },
    {
      name: 'Rhode Island',
      id: 'RI',
    },
    {
      name: 'South Carolina',
      id: 'SC',
    },
    {
      name: 'South Dakota',
      id: 'SD',
    },
    {
      name: 'Tennessee',
      id: 'TN',
    },
    {
      name: 'Texas',
      id: 'TX',
    },
    {
      name: 'Utah',
      id: 'UT',
    },
    {
      name: 'Vermont',
      id: 'VT',
    },
    {
      name: 'Virginia',
      id: 'VA',
    },
    {
      name: 'Washington',
      id: 'WA',
    },
    {
      name: 'West Virginia',
      id: 'WV',
    },
    {
      name: 'Wisconsin',
      id: 'WI',
    },
    {
      name: 'Wyoming',
      id: 'WY',
    },
  ],
  caseTypes: [
    { id: 0, name: 'Subacute' },
    { id: 1, name: 'MLTC' },
  ],

  pendingChances: [
    { id: 100, name: '100% chance of approval' },
    { id: 75, name: '75% chance of approval' },
    { id: 50, name: '50% chance of approval' },
    { id: 25, name: '25% chance of approval' },
    { id: 0, name: '0% chance of approval' },
  ],

  therapyServices: { PT: 18, OT: 19, SLP: 20 },

  noAuthPayers: {
    MEDICAID_PENDING: 1,
    MEDICARE: 2,
    PRIVATE_PAY: 3,
    MEDICAID: 4,
    HOSPICE: 5,
    SUPPLEMENTAL_INSURANCE: 6,
  },
  auto_task_settings: {
    ENDING_AUTH: 5,
    AUTH_REQUEST: 6,
  },
  linesOfBusiness: { PART_B: 13 },
  ruleTypes: {
    MINUTES_PER_DAY: 1,
    DAYS_PER_WEEK: 2,
    WEEKS_PER_MONTH: 3,
    COST_PER_DAY: 4,
    QUANTITY_PER_CONDITION: 5,
    QUANTITY_PER_TREATMENTS: 6,
    AMOUNT_PER_DISCIPLINES: 7,
    COST_PER_DOSE: 8,
    TIMES_PER_DAY: 9,
    DOSES_PER_DAY: 10,
    UNITS_PER_DAY: 11,
    UNITS_PER_WEEK: 12,
    DOSES_PER_WEEK: 13,
    MINUTES_PER_WEEK: 14,
    INCLUDE_ITEMS_LOWER_LEVELS: 15,
    ADL_SCORE: 16,
    MENTAL_STATUS_SCORE: 17,
    COGNITIVE_SCORE: 18,
    ANTICIPATED_LENGTH_OF_STAY_PER_DAY: 19,
    MODULES_PER_DAY: 20,
    SESSIONS_PER_DAY: 21,
    PERCENTAGE: 22,
    AVG_MINUTES_PER_WEEK: 23,
    CAPSULES_PER_MONTH: 24,
  },

  auth_status: {
    APPROVED: 1,
    APPROVED_ENDED: 2,
    DENIAL_RECEIVED: 3,
    DENIED: 4,
    PENDING: 5,
    MISSING: 6,
    RETRO_ISSUE: 7,
    ON_HOLD: 8,
    ALL_TRACKED: [1, 3, 5, 6, 7],
  },
  resident_status: {
    ACTIVE: 1,
    DENIED: 2,
    DISCHARGED_COMMUNITY: 3,
    DISCHARGED_HOSPITAL: 4,
    DISCHARGED_ANOTHER_SNF: 5,
    LEAVE_OF_ABSENCE: 6,
    EXPIRED: 7,
    MEDICAID_PENDING: 8,
    PAYER_CHANGED: 9,
    UNKNOWN: 10,
    UNTRACKED: 11,
    DISCHARGED_ALF: 12,
    DISCHARGED_SHELTER: 13,
    DISCHARGED_AMA: 14,
    DISCHARGED_OTHER: 15,
    IMPORTED: 16,
    DENIED_SUBACUTE: 17,
    DENIED_MLTC: 18,
    DENIED_SUBACUTE_MLTC: 19,
    PRE_ADMIT: 20,
    DISCHARGED_PRE_ADMIT: 21,
  },
  case_status: {
    ACTIVE: 1,
    DENIED: 2,
    DISCHARGED_COMMUNITY: 3,
    DISCHARGED_HOSPITAL: 4,
    DISCHARGED_ANOTHER_SNF: 5,
    LEAVE_OF_ABSENCE: 6,
    EXPIRED: 7,
    MEDICAID_PENDING: 8,
    PAYER_CHANGED: 9,
    UNKNOWN: 10,
    UNTRACKED: 11,
    DISCHARGED_ALF: 12,
    DISCHARGED_SHELTER: 13,
    DISCHARGED_AMA: 14,
    DISCHARGED_OTHER: 15,
    IMPORTED: 16,
    DENIED_SUBACUTE: 17,
    DENIED_MLTC: 18,
    DENIED_SUBACUTE_MLTC: 19,
  },
  report_types: {
    TRIPLE_CHECK: 1,
    BILLING: 2,
    UPDATES_SCHEDULE: 3,
    NO_AUTHS: 4,
    NO_UPDATES: 5,
    PAYERS: 6,
    LOS_DISCHARGE: 7,
    CLIENT_DETAILS: 8,
    CHANGED_PLANNED_DISCHARGES: 9,
    FILTERED_AUTH: 10,
    TASKS_DUE: 11,
    UPDATES_DUE: 12,
    STAFF_CASELOAD: 13,
    LOS_DISCHARGE_2: 14,
    HIGHER_LEVEL_AUTHS_CREATED: 15,
    HIGHER_LEVEL_DAYS_COVERED_REVENUE: 16,
    HIGHER_LEVEL_ADMISSIONS_CREATED: 17,
    DENIAL_REPORT: 18,
    RETRO_AUTHS_REPORT: 19,
    HIGHER_LEVEL_RETRO_AUTHS_CREATED: 20,
    THERAPY_REPORT: 21,
    PAYER_EXCEPTIONS: 22,
    BULK_TASKS_REPORT: 23,
    FAXES_REPORT: 24,
    RETRO_STAFF_CASELOAD: 25,
    PLANNED_DISCHARGE: 26,
    DISMISSED_PCC_CENSUS: 27,
    PAYERS_BY_STATE: 28,
    UR_REPORT: 32,
    PRE_ADMIT_REPORT: 34,
    FACILITIES_REPORT: 35,
  },
  authorization_type: {
    CUSTODIAL_ROOM_AND_BOARD: 1,
    CO_INSURANCE: 2,
    HOSPITAL_BEDHOLD: 3,
    THERAPEUTIC_LEAVE: 4,
    LEVEL_1: 5,
    LEVEL_2: 6,
    LEVEL_3: 7,
    LEVEL_4: 8,
    LEVEL_5: 9,
    MEDICARE: 10,
    SINGLE_CASE_AGREEMENT_RATE: 11,
    FLAT_RATE: 12,
    ALOC: 13,
    VENT: 14,
    PHARMACY: 15,
    UPDATES_ONLY: 16,
    COINSURANCE_VENT: 17,
    PART_B: 18,
    MEDICAID: 19,
    LEVEL_6: 20,
    PENDING: 21,
    AID_TO_CONTINUE: 22,
    NO_UPDATES_COINSURANCE: 23,
    SKILLED_NURSING_LOC: 24,
    SUBACUTE_LOC: 25,
    ACUTE_LOC: 26,
    LEVEL_7: 27,
    HOSPICE: 28,
  },
  levels: {
    LEVEL_1: 5,
    LEVEL_2: 6,
    LEVEL_3: 7,
    LEVEL_4: 8,
    LEVEL_5: 9,
    LEVEL_6: 20,
    LEVEL_7: 27,
  },
  appeal_status: {
    IN_REVIEW: 1,
    UPHELD: 2,
    OVERTURNED: 3,
    APPEAL_SENT: 4,
    CANCELLED: 5,
    CLAIM_APPEAL: 6,
    POSTED_ON_TRELLO: 7,
  },
  denial_type: {
    NOMNC_INSURANCE_MCR_HMO_DENIAL: 1,
    COMMERCIAL_HMO_DENIAL: 2,
    LEVEL_DENIAL: 3,
    CARVE_OUT_DENIAL: 4,
    MCD_HMO_SKILLED_STAY_DENIAL: 5,
    MCD_HMO_ALOC_DENIAL: 6,
    MCD_HMO_CUSTODIAL_DENIAL: 7,
    MLTC_CUSTODIAL_DENIAL: 8,
    MLTC_VENT_DENIAL: 9,
    MLTC_COINSURANCE_DENIAL: 10,
    BEDHOLD_DENIAL: 11,
    THERAPEUTIC_LEAVE_DENIAL: 12,
    RETRO_REQUEST_DENIAL: 13,
    BENEFITS_EXHAUSTED: 14,
    ADMISSION_DENIAL: 15,
    PART_B_DENIAL: 16,
    NOMNC_FACILITY_MCR_HMO_DENIAL: 19,
  },
  denial_status: {
    DENIAL_RECEIVED: 1,
    DENIAL_NOT_APPEALING: 2,
    APPEAL_SENT: 3,
    APPEAL_IN_REVIEW: 4,
    UPHELD: 5,
    OVERTURNED: 6,
  },
  appeal_type: {
    KEPRO: 1,
    LIVANTA: 2,
    INTERNAL: 3,
    EXTERNAL: 4,
    SKILLED_AUTH: 5,
    ALOC_AUTH: 6,
    CUSTODIAL_AUTH: 7,
    COINSURANCE_COPAY_AUTH: 8,
    LEVEL_CHANGE: 9,
    CARVE_OUT: 10,
    OTHER: 11,
    RETRO_AUTH: 12,
    PEER_TO_PEER: 13,
    FAIR_HEARING: 14,
    DOH: 15,
    DFS: 16,
    ALJ_HEARING: 17,
  },
  task_type: {
    INITIAL_EVALUATIONS: 1,
    SUBACUTE_CONCURRENT_REVIEW: 2,
    MLTC_CONCURRENT_REVIEW: 3,
    LEVEL_CHANGE_REQUEST: 4,
    SKILLED_AUTH_REQUEST: 5,
    ALOC_AUTH_REQUEST: 6,
    CUSTODIAL_AUTH_REQUEST: 7,
    COINSURANCE_COPAY_AUTH_REQUEST: 8,
    CARVE_OUT_REQUEST: 9,
    OTHER_AUTH_REQUEST: 10,
    OTHER_UPDATE_ONE_TIME_UPDATE: 11,
    ADMISSION_NOTIFICATION: 12,
    DISCHARGE_NOTIFICATION: 13,
    AUTHORIZATION_REQUEST_DUE: 14,
    AUTHORIZATION_EXPIRING_IN_24_HOURS: 15,
    // eslint-disable-next-line
    AUTHORIZATION_REQUEST_DUE: 16,
    UPDATE_DUE_TOMORROW: 17,
    UPDATE_DUE_LCD_IN_2_DAYS: 18,
    EVALS_DUE_BY: 19,
    ISSUE_NOMNC: 20,
    NOTIFICATION_CALL_TO_UHC: 21,
    FOLLOW_UP_ON_LEVEL_CHANGE: 22,
    INITIAL_AUTH_REQUEST: 23,
  },
  resolution_type: {
    NEW_PAYER: 1,
    DISCHARGED: 2,
    AUTH_EXTENDED: 3,
    NA: 4,
    RESCINDED: 5,
  },
  discharge_location: {
    HOME_COMMUNITY: 1,
    HOSPITAL: 2,
    ASSISTED_LIVING_FACILITY: 3,
    ANOTHER_SNF: 4,
    SHELTER: 5,
    AMA: 6,
    EXPIRED: 7,
    OTHER: 8,
    PAYER_CHANGE: 9,
    PRE_ADMIT_NO_SHOW: 10,
  },
  line_of_business: {
    MEDICAID_HMO: 1,
    MEDICARE_ADVANTAGE: 2,
    COMMERCIAL_HMO: 4,
    MLTC: 5,
    WORKERS_COMPENSATION: 6,
    NO_FAULT_INSURANCE: 7,
    FIDA: 8,
    NA: 10,
    PART_B: 13,
  },
  post_lcd_option: {
    DISCHARGING_HOME: 1,
    DISCHARGING_OTHER: 2,
    PAYER_CHANGING: 3,
    LTC_CUSTODIAL_AUTH_NEEDED: 4,
    SHORT_TERM_CUSTODIAL_AUTH_NEEDED: 5,
    TBD: 6,
    DISENROLLED: 7,
  },
  roles: {
    ADMIN: 1,
    SUPERVISOR: 2,
    CASE_MANAGER: 3,
    QUALITY_REVIEWER: 4,
    TEAM_LEADER: 5,
    BACKEND_REVIEWER: 6,
    COVERING_USER: 10,
  },
  events: {
    ADMISSION: 1,
    AUTHORIZATION: 2,
    DENIAL: 3,
    BEDHOLD: 4,
    PAYER_CHANGE: 5,
    DISCHARGE: 6,
    PLANNED_DISCHARGE: 7,
    READMISSION: 8,
  },
};

export const weekDays = [
  { id: 2, name: 'Mon' },
  { id: 3, name: 'Tue' },
  { id: 4, name: 'Wed' },
  { id: 5, name: 'Thur' },
  { id: 6, name: 'Fri' },
];

export const weekDaysObj = {
  '2': 'Mon',
  '3': 'Tue',
  '4': 'Wed',
  '5': 'Thur',
  '6': 'Fri',
};

export const authLetterStatus = [
  { id: 'pending', name: 'Pending' },
  { id: 'faxed received', name: 'Fax received' },
  { id: 'faxed not received', name: 'Fax not received' },
  { id: 'mail to facility', name: 'Mail to facility' },
  { id: 'mail to patient', name: 'Mail to patient' },
  { id: 'emailed received', name: 'Email received' },
  { id: 'emailed not received', name: 'Email not received' },
  {
    id: 'insurance don’t send letters',
    name: 'Insurance doesn’t send letters',
  },
  { id: 'website not posted', name: 'Website not posted' },
  { id: 'website copy saved', name: 'Website copy saved' },
];
