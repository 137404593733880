import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { isFuture, isValid, format, addDays } from 'date-fns';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { Form } from 'react-final-form';
import {
  required,
  // number,
  email,
  useDataProvider,
  useNotify,
  cacheDataProviderProxy,
} from 'react-admin';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  Switch,
  Button,
  IconButton,
} from '@material-ui/core';
import { calculateDaysUsed, getIntValue } from '../../../../utils';
import { authGet } from '../../../../server';
import {
  AutocompleteInput,
  ReferenceInput,
  FormTextField,
  SelectComponent,
  FileInput,
  composeValidators,
  CheckboxInput,
  RadioInput,
  normalizePhone,
  handleNumbers,
  getDateInputValue,
  minLength,
  validateEmailOnSubmit,
  validatePhoneOnSubmit,
  validateDate,
  maxDate,
  minDate,
  minReasonLength,
} from '../modalForms';
import {
  AuthFields,
  authOnChange as importedAuthOnChange,
} from './authorization';
import { useStyles } from '../modal.styles';
import {
  SaveButton,
  AddIcon,
  RemoveIcon,
  SearchIcon,
  CloseIcon,
} from '../../../../design';
import { constants } from '../../../../utils';
import { CheckEligibility } from './checkEligibility';
import { useGetCaseId } from '../../../../hooks';
import { uiActions } from '../../../../state/actions';
const {
  caseTypes,
  therapyServices,
  noAuthPayers,
  line_of_business,
} = constants;
const noAuthPayersArray = Object.values(noAuthPayers);

const HIGHEST_NO_AUTH_PAYER_ID = 6;
const PART_B_AUTH_ID = 18;

export const CasePayerForm = ({
  caseId,
  record = {},
  isEdit,
  handleClose,
  refresh,
  meta = {}, // meta.origin values 'add' or 'edit'. If empty then user is using the FAB or from denial.
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const caseIdFromUrl = useGetCaseId();
  const dataProvider = cacheDataProviderProxy(useDataProvider());
  const notify = useNotify();
  const [addAuth, setAddAuth] = useState(false);
  const [formData, setFormData] = useState({
    case_id: caseId,
  });
  const [otherPayers, setOtherPayers] = useState([]);
  const [activeSecondaryPayers, setActiveSecondaryPayers] = useState([]);
  const [hasSecondaryPayers, setHasSecondaryPayers] = useState(true);
  const [allActivePayers, setAllActivePayers] = useState([]);
  const [casePrimaryPayer, setCasePrimaryPayer] = useState();
  const [payerRepInputs, setPayerRepInputs] = useState({});
  const [isInNetwork, setIsInNetwork] = useState(true);
  const isFromFAB = !meta.origin;
  const [showEligibility, setShowEligibility] = useState(false);
  const [seekPayer, setSeekPayer] = useState(false);
  const [mltcPayer, setMltcPayer] = useState(false);
  const [subLevelChoices, setSubLevelChoices] = useState([]);
  const [pt_option, setPtOption] = useState('units');
  const [ot_option, setOtOption] = useState('units');
  const [speech_option, setSpeechOption] = useState('units');
  const [facilityLobMapping, setFacilityLobMapping] = useState({});

  function getOutpatientServicesValues(values) {
    if (values.authorization_type_id !== PART_B_AUTH_ID) return;
    const arr = [];
    // eslint-disable-next-line default-case
    if (values.pt) {
      arr.push({
        service_id: therapyServices.PT,
        frequency: values.pt_frequency,
        minutes: values.pt_minutes,
      });
    }
    if (values.ot) {
      arr.push({
        service_id: therapyServices.OT,
        frequency: values.ot_frequency,
        minutes: values.ot_minutes,
      });
    }
    if (values.speech) {
      arr.push({
        service_id: therapyServices.SLP,
        frequency: values.speech_frequency,
        minutes: values.speech_minutes,
      });
    }
    return arr.length && arr;
  }
  useEffect(() => {
    async function getActiveCaseTypes() {
      const response = await fetchResource(`active-case-types`);
      if (response !== 'error') {
        const { active_case_types } = response;
        if (active_case_types === 'subacute') {
          setMltcPayer(true);
        }
      }
    }
    if (isFromFAB) getActiveCaseTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFromFAB]);

  useEffect(() => {
    if (isEdit && !!Object.keys(record).length) {
      populateFields(record);
    }
  }, [isEdit, record]);

  useEffect(() => {
    const caseid = caseId || formData.case_id;

    async function getCasePayers() {
      const response = await fetchResource(`cases/${caseid}/case-payers`, {
        is_active: true,
      });
      if (response !== 'error') {
        const primaryPayerId = getPrimaryPayer(record, response)?.id;
        const others = response.filter(payer => payer.id !== primaryPayerId);
        setOtherPayers(others);
        setAllActivePayers(response);
        setCasePrimaryPayer(getPrimaryPayer(record, response));
      }
    }
    if (caseid) {
      getCasePayers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId, formData.case_id]);

  useEffect(() => {
    if (formData.cases_payers_id && formData.change_type === 'changePriority') {
      const selectedPayer = allActivePayers.find(
        payer => payer.id === parseInt(formData.cases_payers_id),
      );
      selectedPayer && populateFields(selectedPayer);
    }
  }, [allActivePayers, formData.cases_payers_id, formData.change_type]);

  useEffect(() => {
    const payerId = formData.payer_id;
    async function checkSeekPayer() {
      const response = await authGet([
        `seek-payer-codes`,
        { payer_id: payerId },
      ]);
      if (response !== 'error') {
        setSeekPayer(response.data);
      }
    }
    checkSeekPayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.payer_id]);

  function populateFields(record) {
    const {
      id,
      bedhold_days_used,
      created_by,
      updated_by,
      vCasesPayerCreatedBy,
      vCasesPayerUpdatedBy,
      is_primary,
      rep_id,
      admit_date,
      ...rest
    } = record;
    const calculatedDays = calculateDaysUsed(record);
    const daysRemaining = parseInt(record.days_of_coverage) - calculatedDays;
    setFormData(f => ({
      ...f,
      ...rest,
      cases_payers_id: id,
      days_remaining: daysRemaining > 0 ? daysRemaining : 0,
      is_primary: !!is_primary,
      rep_id: rep_id ?? undefined,
    }));
    setPayerRepInputs(getRepProps(record));
  }

  async function getActiveSecondaryPayers(caseid) {
    const response = await fetchResource('cases-payers-secondary/list', {
      case_id: caseid,
    });
    if (response !== 'error') {
      setActiveSecondaryPayers(response);
    }
    setHasSecondaryPayers(response !== 'error' && !!response.length);
    return response;
  }

  const updateTasksCount = useCallback(() => {
    if (caseIdFromUrl) {
      setTimeout(() => {
        dispatch(uiActions.getCaseTasksCount(caseIdFromUrl));
      }, 6000);
    }
  }, [caseIdFromUrl, dispatch]);

  const onSubmit = value => {
    const {
      change_type,
      rep_id: _rep_id,
      rep_phone: _rep_phone,
      rep_email: _rep_email,
      rep_fax: _rep_fax,
      days_of_coverage: _days_of_coverage,
      days_remaining: _days_remaining,
      days_used: _days_used,
      active_days_used: _active_days_used,
      'not-active': _notActive,
      payer_id: _payer_id,
      is_primary,
      ...rest
    } = value;
    const primaryChangeFromId = casePrimaryPayer ? casePrimaryPayer.id : null;
    const days_of_coverage = getIntValue(_days_of_coverage);
    const days_remaining = getIntValue(_days_remaining);
    const days_used = getIntValue(_days_used);
    const active_days_used = getIntValue(_active_days_used);
    const not_active = getIntValue(_notActive);
    const payer_id = getIntValue(_payer_id);
    // if rep fields were edited create new rep.
    const rep_id = isEqual(getRepProps(value), payerRepInputs)
      ? getIntValue(_rep_id)
      : 0;
    const data = {
      ...rest,
      days_of_coverage,
      days_remaining,
      days_used,
      active_days_used,
      not_active,
      payer_id,
      rep_id,
      rep_phone: validatePhoneOnSubmit(_rep_phone),
      rep_fax: validatePhoneOnSubmit(_rep_fax),
      rep_email: validateEmailOnSubmit(_rep_email),
      prev_primary: primaryChangeFromId,
      payer_facility_id: facilityLobMapping?.[rest.line_of_business_id + ''],
    };
    const outpatient_services = getOutpatientServicesValues(value);
    if (outpatient_services === 'error') return Promise.resolve();
    if (isEdit || change_type === 'changePriority') {
      const updateData =
        change_type === 'changePriority'
          ? dataProvider.update('change-payer', {
              id: value.cases_payers_id,
              data: {
                ...data,
                outpatient_services,
                is_primary: isFromFAB || is_primary,
                all_payers:
                  formData.is_primary ||
                  (isFromFAB &&
                    (value.change_type !== 'changePriority' ||
                      otherPayers.length))
                    ? allActivePayers
                    : otherPayers,
              },
              previousData: { ...record },
            })
          : dataProvider.update('cases-payers', {
              id: isEdit ? record.id : value.cases_payers_id,
              data: {
                ...data,
                outpatient_services,
                is_primary: isFromFAB || is_primary,
                all_payers:
                  formData.is_primary ||
                  (isFromFAB &&
                    (value.change_type !== 'changePriority' ||
                      otherPayers.length))
                    ? allActivePayers
                    : otherPayers,
              },
              previousData: { ...record },
            });
      return updateData
        .then(({ data }) => {
          notify('form.updated');
          handleClose();
          updateTasksCount();
          typeof refresh === 'function' && refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    } else {
      return dataProvider
        .create('cases-payers', {
          data: {
            ...data,
            outpatient_services,
            is_primary: isFromFAB || is_primary,
            all_payers:
              formData.is_primary ||
              (isFromFAB &&
                (value.change_type !== 'changePriority' || otherPayers.length))
                ? allActivePayers
                : otherPayers,
          },
        })
        .then(({ data }) => {
          notify('form.created');
          handleClose();
          updateTasksCount();
          typeof refresh === 'function' && refresh();
        })
        .catch(error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning',
          ),
        );
    }
  };

  const openAuth = () => {
    setFormData(f => ({ ...f, update_type: [], update_weekday: [] }));
    setAddAuth(true);
  };

  const removeAuthFields = () => {
    const {
      insurance_auth_number,
      authorization_status_id,
      authorization_type_id,
      rev_code,
      start_date,
      days_approved,
      end_date,
      days_available,
      letter_received,
      auth_letter_status_id,
      auth_notes,
      authorization_id,
      update_type,
      update_weekday,
      initial_update_date,
      update_frequency,
      ...rest
    } = formData;
    setFormData(rest);
  };

  const authOnChange = async (eventOrValue, _name, type) => {
    return importedAuthOnChange({
      eventOrValue,
      _name,
      type,
      isEdit,
      setFormData,
      formData,
      setSubLevelChoices,
    });
  };

  const customOnChange = async (eventOrValue, _name, type) => {
    // when using a react-admin input, onChange returns
    // the value instead of the target.
    let value = eventOrValue;
    let name = _name;
    if (typeof eventOrValue === 'object') {
      const target = eventOrValue.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    }
    if (type === 'number') {
      value = handleNumbers(value);
    }
    if (type === 'date') {
      value = getDateInputValue(value);
    }

    if (name === 'active_date' || name === 'copay_start_day') {
      const {
        active_date: _active_date,
        copay_start_day: _copay_start_day,
        days_used = 0,
        days_remaining,
        days_of_coverage,
        is_primary,
      } = formData;
      const active_date =
        name === 'active_date'
          ? new Date(`${value} 00:00`)
          : new Date(`${_active_date} 00:00`);
      const copay_start_day =
        name === 'copay_start_day' ? value : _copay_start_day;
      if (isValid(active_date)) {
        const fields = {};
        if (copay_start_day) {
          fields.copay_start_date = format(
            addDays(active_date, +copay_start_day - +days_used - 1),
            'yyyy-MM-dd',
          );
        }
        if (
          !is_primary &&
          name === 'active_date' &&
          !isNaN(days_remaining) &&
          days_of_coverage
        ) {
          fields.copay_end_date = format(
            addDays(active_date, +days_remaining - 1),
            'yyyy-MM-dd',
          );
        }
        setFormData(f => ({
          ...f,
          [name]: value,
          ...fields,
          line_of_business_id:
            name === 'active_date' ? null : formData.line_of_business_id,
        }));
      } else {
        setFormData(f => ({
          ...f,
          [name]: value,
          line_of_business_id:
            name === 'active_date' ? null : formData.line_of_business_id,
        }));
      }
      return;
    }

    if (name === 'days_of_coverage') {
      const { days_used, active_date: _active_date } = formData;
      const daysUsed = getFieldIntValue(days_used);
      const daysOfCoverage = getFieldIntValue(value);
      const active_date = new Date(`${_active_date} 00:00`);
      const calculatedDays = isEdit
        ? calculateDaysUsed({
            days_used: daysUsed,
            active_days_used: getFieldIntValue(record.active_days_used),
            bedhold_days_used: getFieldIntValue(record.bedhold_days_used),
          })
        : daysUsed;
      const days_remaining =
        daysOfCoverage - calculatedDays > 0
          ? daysOfCoverage - calculatedDays
          : 0;
      const fields = {
        days_of_coverage: value,
        days_remaining,
      };
      if (isValid(active_date)) {
        fields.copay_end_date = format(
          addDays(active_date, +days_remaining - 1),
          'yyyy-MM-dd',
        );
      }
      setFormData({
        ...formData,
        ...fields,
      });
      return;
    }
    if (name === 'days_used') {
      const {
        days_of_coverage,
        active_date: _active_date,
        copay_start_day,
      } = formData;
      const daysUsed = getFieldIntValue(value);
      const daysOfCoverage = getFieldIntValue(days_of_coverage);
      const active_date = new Date(`${_active_date} 00:00`);
      const calculatedDays = isEdit
        ? calculateDaysUsed({
            days_used: daysUsed,
            active_days_used: record.active_days_used,
            bedhold_days_used: record.bedhold_days_used,
          })
        : daysUsed;
      const days_remaining =
        daysOfCoverage - calculatedDays > 0
          ? daysOfCoverage - calculatedDays
          : 0;
      const fields = {
        days_used: value,
        days_remaining,
      };
      if (isValid(active_date)) {
        if (copay_start_day) {
          fields.copay_start_date = format(
            addDays(active_date, +copay_start_day - +value - 1), // need to include the admit date
            'yyyy-MM-dd',
          );
        }
        if (days_of_coverage) {
          fields.copay_end_date = format(
            addDays(active_date, +days_remaining - 1),
            'yyyy-MM-dd',
          );
        }
        setFormData({
          ...formData,
          [name]: value,
          days_remaining,
          ...fields,
        });
      }
      setFormData({
        ...formData,
        ...fields,
      });
      return;
    }

    if (name === 'rep_id') {
      if (value > 0) {
        const data = await fetchResource(`/payer-reps/${value}`);
        if (data !== 'error') {
          const rep = sanitizeRep(data);
          setPayerRepInputs(rep);
          setFormData({ ...formData, rep_id: value, ...rep });
          return;
        }
      }
    }

    if (name === 'change_type' || name === 'case_id') {
      const caseId = name === 'case_id' ? value : formData.case_id;
      const changeType = name === 'change_type' ? value : formData.change_type;
      let newValues = {
        case_id: caseId,
        change_type: changeType,
      };
      if (caseId && changeType === 'changePriority') {
        const response = await getActiveSecondaryPayers(caseId);
        if (response !== 'error') {
          // if (response.length === 1) {
          //   const selectedPayer = allActivePayers.filter(
          //     payer => payer.id === response[0].id,
          //   );
          //   newValues = { ...newValues, ...selectedPayer[0] };
          // }
          setFormData({
            ...newValues,
            cases_payers_id: response.length === 1 ? response[0].id : undefined,
          });
        } else {
          setFormData({ ...newValues });
        }
      } else {
        setFormData({ ...newValues });
        setHasSecondaryPayers(true);
      }
      return;
    }

    if (name === 'payer_id' || name === 'line_of_business_id') {
      setSubLevelChoices([]);
      const fields = {
        [name]: value,
        line_of_business_id: name === 'payer_id' ? null : value,
        authorization_type_id: null,
        sub_level: null,
      };
      if (name === 'payer_id' && noAuthPayersArray.includes(value)) {
        fields['line_of_business_id'] = line_of_business.NA;
      }
      setFormData(f => ({ ...f, ...fields }));
      return;
    }

    // if (
    //   name === 'cases_payers_id' &&
    //   formData.change_type === 'changePriority'
    // ) {
    //   if (value && Array.isArray(allActivePayers)) {
    //     const selectedPayer = allActivePayers.filter(
    //       payer => payer.id === parseInt(value),
    //     );
    //     setFormData({ ...formData, [name]: value, ...selectedPayer });
    //     return;
    //   }
    // }

    setFormData({ ...formData, [name]: value });
  };

  const payerOnClick = useCallback(suggestion => {
    const lobs = suggestion?.payers_facilities?.[0]?.line_of_businesses;
    if (Array.isArray(lobs)) {
      const lobObj = lobs.reduce((acc, cur) => {
        acc[cur.id + ''] = cur.payer_facility_lobs?.payer_facility_id;
        return acc;
      }, {});
      setFacilityLobMapping(lobObj);
    }
  }, []);

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...payerRepInputs,
          ...formData,
        }}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
        }) => {
          const isPayerChange =
            !!values.change_type || (!record.is_primary && !!values.is_primary);
          const newSecondaryWithCopay =
            meta.origin === 'add' &&
            !values.is_primary &&
            !!values.copay_start_date;
          const payerId = isEdit ? record.payer_id : values.payer_id;
          const showInsuranceInputs =
            values.change_type !== 'changePriority' && payerId === 0;
          const showRepLists = payerId >= HIGHEST_NO_AUTH_PAYER_ID;
          const isActive =
            values.change_type === 'changePriority' ||
            (values.active_date &&
              !isFuture(new Date(`${values.active_date} 00:00`)) &&
              (!values.inactive_date ||
                isFuture(new Date(`${values.inactive_date} 00:00`))));
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                {isFromFAB && (
                  <SelectComponent
                    customOnChange={customOnChange}
                    label='Select change type'
                    name='change_type'
                    fullWidth
                    style={{ marginRight: 0, marginLeft: 0 }}
                    choices={[
                      {
                        id: 'newPayer',
                        name: isFromFAB
                          ? 'Add new primary payer'
                          : 'Add new payer',
                      },
                      {
                        id: 'changePriority',
                        name: 'Change secondary to primary',
                      },
                      ...(mltcPayer
                        ? [
                            {
                              id: 'changeMltc',
                              name: 'Change to MLTC payer',
                            },
                          ]
                        : []),
                    ]}
                  />
                )}
                {(meta.origin || values.change_type) && (
                  <Fragment>
                    {!isEdit && !caseId && (
                      <div style={{ paddingBottom: 15, marginRight: 3 }}>
                        <AutocompleteInput
                          reference='cases/list'
                          customOnChange={customOnChange}
                          name='case_id'
                          label='Case'
                          validate={required()}
                          required
                          fullWidth
                          openOnFocus
                          autocompleteProps={{ openOnFocus: true }}
                          style={{ marginRight: 0, marginLeft: 0 }}
                          options={{
                            filter: {
                              division: 'all',
                              active: isEdit ? 0 : 1,
                            },
                          }}
                        />
                      </div>
                    )}
                    {isEdit &&
                      values.change_type !== 'changePriority' &&
                      values.change_type !== 'changeMltc' &&
                      values.case_id >= 0 && (
                        <ReferenceInput
                          key={values.case_id}
                          reference='cases-payers/list'
                          customOnChange={customOnChange}
                          name='cases_payers_id'
                          label='Payer'
                          validate={required()}
                          required
                          fullWidth
                          disabled={isEdit}
                          style={{ marginRight: 0, marginLeft: 0 }}
                          resetOption={{ id: 0, name: '+Out Of Network' }}
                          getSelectedChoice={payerOnClick}
                          options={{
                            filter: {
                              case_id: values.case_id,
                              is_active:
                                values.change_type === 'changePriority'
                                  ? true
                                  : undefined,
                            },
                          }}
                        />
                      )}
                    {!isEdit &&
                      values.change_type !== 'changePriority' &&
                      values.change_type !== 'changeMltc' &&
                      values.case_id >= 0 && (
                        <Fragment>
                          <ToggleInsuranceNetwork
                            state={isInNetwork}
                            setState={setIsInNetwork}
                            name='isInNetwork'
                          />
                          <AutocompleteInput
                            key={isInNetwork + ''}
                            reference='facility-payers/list'
                            customOnChange={customOnChange}
                            name='payer_id'
                            label='Payer'
                            validate={required()}
                            required
                            fullWidth
                            getSelectedChoice={payerOnClick}
                            // autocompleteProps={{ openOnFocus: true }}
                            options={{
                              filter: { in_network: isInNetwork },
                            }}
                            viewAll
                          />
                        </Fragment>
                      )}
                    {Array.isArray(activeSecondaryPayers) &&
                      values.case_id > 0 &&
                      values.change_type === 'changePriority' && (
                        <Fragment>
                          {hasSecondaryPayers ? (
                            // <Fragment>
                            <SelectComponent
                              customOnChange={customOnChange}
                              label='Payer'
                              name='cases_payers_id'
                              getSelectedChoice={payerOnClick}
                              fullWidth
                              style={{ marginRight: 0, marginLeft: 0 }}
                              choices={activeSecondaryPayers}
                            />
                          ) : (
                            //   {/* <FormTextField
                            //     name='effective_date'
                            //     label='Effective Date'
                            //     customOnChange={customOnChange}
                            //     type='date'
                            //     validate={composeValidators([
                            //       required(),
                            //       validateDate(),
                            //     ])}
                            //     required
                            //   />
                            // </Fragment> */}
                            <DialogContentText>
                              This case has no active secondary payers.
                            </DialogContentText>
                          )}
                        </Fragment>
                      )}
                    {showInsuranceInputs &&
                      values.change_type !== 'changeMltc' && (
                        <Fragment>
                          <DialogContentText
                            align='left'
                            variant='h6'
                            className={classes.header}
                          >
                            Provider details
                          </DialogContentText>
                          <Divider className={classes.divider} />
                          <div className={clsx(classes.inputContainerWrap)}>
                            <FormTextField
                              name='insurance_name'
                              validate={required()}
                              required
                              label='Company name'
                              customOnChange={customOnChange}
                            />
                            <ReferenceInput
                              key={[
                                `${payerId}_lob`,
                                values.active_date,
                                isEdit,
                                payerId,
                              ]}
                              reference='lob/list'
                              customOnChange={customOnChange}
                              name='line_of_business_id'
                              label='Type (LOB)'
                              validate={required()}
                              required
                              options={{
                                filter: {
                                  payer_id: payerId,
                                  effective_date: values.active_date,
                                  in_network: isInNetwork,
                                  include_lob: isEdit
                                    ? values.line_of_business_id
                                    : undefined,
                                },
                              }}
                            />
                          </div>
                          <div className={clsx(classes.inputContainerWrap)}>
                            <FormTextField
                              name='group_provider'
                              label='Group provider'
                              customOnChange={customOnChange}
                            />
                            <FormTextField
                              name='pin'
                              label='PIN'
                              customOnChange={customOnChange}
                            />
                          </div>
                          <FormTextField
                            name='remit_address'
                            validate={required()}
                            required
                            label='Remit address'
                            fullWidth
                            customOnChange={customOnChange}
                          />
                        </Fragment>
                      )}

                    {/* {values.change_type !== 'changePriority' && ( */}
                    <Fragment>
                      {values.change_type !== 'changeMltc' && (
                        <Fragment>
                          <DialogContentText
                            align='left'
                            variant='h6'
                            className={classes.header}
                          >
                            Member Details
                          </DialogContentText>
                          <Divider className={classes.divider} />
                          <div className={clsx(classes.inputContainerWrap)}>
                            <FormTextField
                              name='active_date'
                              validate={composeValidators([
                                required(),
                                validateDate(),
                                ...(isFromFAB
                                  ? [
                                      maxDate(
                                        format(new Date(), 'yyyy-MM-dd'),
                                        "Effective date can't be in the future",
                                      ),
                                    ]
                                  : []),
                              ])}
                              required
                              label='Effective date'
                              customOnChange={customOnChange}
                              type='date'
                              style={{
                                marginLeft: 0,
                                marginRight:
                                  values.change_type === 'changeMltc' && 0,
                              }}
                            />
                            {values.change_type !== 'changeMltc' && (
                              <FormTextField
                                name='inactive_date'
                                label='Inactive date'
                                customOnChange={customOnChange}
                                type='date'
                                validate={composeValidators([
                                  validateDate(),
                                  ...(isFromFAB
                                    ? [
                                        minDate(
                                          format(new Date(), 'yyyy-MM-dd'),
                                          "Inactive date can't be in the past",
                                        ),
                                      ]
                                    : []),
                                ])}
                              />
                            )}
                          </div>
                          <div className={clsx(classes.inputContainerWrap)}>
                            <FormTextField
                              name='residents_insurance_id'
                              validate={
                                values.payer_id > HIGHEST_NO_AUTH_PAYER_ID
                                  ? required()
                                  : undefined
                              }
                              required={
                                values.payer_id > HIGHEST_NO_AUTH_PAYER_ID
                              }
                              label='Member ID #'
                              customOnChange={customOnChange}
                              style={{ marginRight: !payerId && 0 }}
                            />

                            {!!payerId && !showInsuranceInputs && (
                              <ReferenceInput
                                key={[
                                  `${payerId}_lob`,
                                  values.active_date,
                                  isEdit,
                                  payerId,
                                ]}
                                reference='lob/list'
                                customOnChange={customOnChange}
                                name='line_of_business_id'
                                label='Type (LOB)'
                                validate={required()}
                                required
                                options={{
                                  filter: {
                                    payer_id: payerId,
                                    effective_date: values.active_date,
                                    in_network: isInNetwork,
                                    include_lob: isEdit
                                      ? values.line_of_business_id
                                      : undefined,
                                  },
                                }}
                                style={{ marginRight: 0 }}
                              />
                            )}
                          </div>
                          <div className={clsx(classes.inputContainerWrap)}>
                            <FormTextField
                              customOnChange={customOnChange}
                              name='days_of_coverage'
                              label='Total benefit days'
                              type='number'
                            />
                            <FormTextField
                              customOnChange={customOnChange}
                              name='days_used'
                              label='Prior days used'
                              type='number'
                            />
                          </div>
                          <div className={clsx(classes.inputContainerWrap)}>
                            {isEdit && (
                              <FormTextField
                                customOnChange={customOnChange}
                                name='active_days_used'
                                label='Auth days used'
                                type='number'
                                disabled
                              />
                            )}
                            <FormTextField
                              customOnChange={customOnChange}
                              name='days_remaining'
                              label='Days remaining'
                              disabled
                              fullWidth={!isEdit}
                              style={{ marginRight: 0 }}
                            />
                          </div>
                        </Fragment>
                      )}

                      {values.change_type !== 'changeMltc' && (
                        <Fragment>
                          <div className={clsx(classes.inputContainerWrap)}>
                            <FormTextField
                              customOnChange={customOnChange}
                              name='copay_start_day'
                              label='Copay starts on day'
                              type='number'
                            />
                            <FormTextField
                              customOnChange={customOnChange}
                              name='copay_start_date'
                              type='date'
                              label='Copay start date'
                              validate={composeValidators([validateDate()])}
                            />
                          </div>
                          <div className={clsx(classes.inputContainerWrap)}>
                            <FormTextField
                              customOnChange={customOnChange}
                              name='copay_end_date'
                              type='date'
                              label='Copay end date'
                              validate={composeValidators([validateDate()])}
                            />
                            <div style={{ flex: '1 0 160px' }} />
                          </div>

                          {!isEdit && values.change_type !== 'changePriority' && (
                            <div style={{ paddingTop: 20 }}>
                              {seekPayer && !showEligibility && (
                                <Button
                                  styles={{ itemAlign: 'center' }}
                                  onClick={() => {
                                    values.payer_id &&
                                    values.residents_insurance_id
                                      ? setShowEligibility(true)
                                      : notify(
                                          'Missing required information. Please include payer and member ID.',
                                          'warning',
                                        );
                                  }}
                                >
                                  <SearchIcon
                                    className={clsx(
                                      classes.leftIcon,
                                      classes.icon,
                                    )}
                                  />
                                  Check Eligibility
                                </Button>
                              )}
                              {showEligibility && (
                                <div
                                  style={{
                                    width: '100%',
                                    textAlign: 'right',
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      setShowEligibility(false);
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                              )}
                              {showEligibility && (
                                <CheckEligibility
                                  params={{
                                    insurance: values.payer_id,
                                    residents_insurance_id:
                                      values.residents_insurance_id,
                                    case_id: caseId || values.case_id,
                                  }}
                                />
                              )}
                            </div>
                          )}
                          <FormTextField
                            customOnChange={customOnChange}
                            name='copay_info'
                            label='Co-pay info'
                            fullWidth
                          />
                          {showRepLists && (
                            <ReferenceInput
                              key={`${payerId}_rep`}
                              reference='reps/list'
                              customOnChange={customOnChange}
                              name='rep_id'
                              label='Payer rep'
                              fullWidth
                              options={{
                                filter: { payer_id: payerId },
                              }}
                              resetOption={{ id: 0, name: 'Add new rep' }}
                            />
                          )}
                          {values.rep_id >= 0 && (
                            <Fragment>
                              <FormTextField
                                customOnChange={customOnChange}
                                name='rep_name'
                                validate={required()}
                                required
                                label='Rep name'
                                fullWidth
                              />
                              <div className={clsx(classes.inputContainerWrap)}>
                                <FormTextField
                                  customOnChange={customOnChange}
                                  name='rep_phone'
                                  type='text'
                                  format='phone'
                                  validate={composeValidators([
                                    required(),
                                    minLength(10, 'Invalid phone number'),
                                  ])}
                                  required
                                  label='Rep phone'
                                />
                                <FormTextField
                                  customOnChange={customOnChange}
                                  name='phone_ext'
                                  label='Ext.'
                                />
                              </div>
                              <div className={clsx(classes.inputContainerWrap)}>
                                <FormTextField
                                  customOnChange={customOnChange}
                                  name='rep_fax'
                                  validate={composeValidators([
                                    minLength(10, 'Invalid phone number'),
                                  ])}
                                  type='text'
                                  format='phone'
                                  label='Rep fax'
                                />
                                <FormTextField
                                  customOnChange={customOnChange}
                                  name='rep_email'
                                  validate={composeValidators([email()])}
                                  label='Rep email'
                                  type='email'
                                />
                              </div>
                              <FormTextField
                                name='rep_comments'
                                label='Rep notes'
                                multiline
                                fullWidth
                                customOnChange={customOnChange}
                                validate={minReasonLength()}
                              />
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                    {/* )} */}
                    {!record.is_primary &&
                      !isFromFAB &&
                      values.change_type !== 'changePriority' && (
                        <CheckboxInput
                          name='is_primary'
                          customOnChange={customOnChange}
                          label='Set as primary payer?'
                          checked={values.is_primary}
                        />
                      )}

                    {isActive && (
                      <Fragment>
                        {allActivePayers.length &&
                        !record.is_primary &&
                        (formData.is_primary ||
                          isFromFAB ||
                          (!isEdit &&
                            allActivePayers?.filter(
                              row => row.id !== formData.cases_payers_id,
                            ).length > 1) ||
                          (isEdit &&
                            otherPayers?.filter(
                              row => row.id !== formData.cases_payers_id,
                            ).length > 1)) ? (
                          <div>
                            <div style={{ marginTop: 10 }}>
                              {`${
                                formData.is_primary || isFromFAB
                                  ? 'Setting this payer as the primary payer '
                                  : 'Adding this payer '
                              }`}
                              will cause the following payer(s) to become
                              deactivated. Check off the payer(s) that you want
                              to remain active.
                            </div>

                            {(formData.is_primary || isFromFAB
                              ? allActivePayers?.filter(
                                  row => row.id !== formData.cases_payers_id,
                                )
                              : otherPayers?.filter(
                                  row => row.id !== formData.cases_payers_id,
                                )
                            ).map(p => (
                              <p>
                                <div style={{ display: 'block' }}>
                                  <CheckboxInput
                                    className={classes.CheckboxInput}
                                    customOnChange={customOnChange}
                                    label={`${p.payers_name}/${p.payer_type ||
                                      ''}`}
                                    value={`${p.id}_is_active`}
                                    name={`${p.id}_is_active`}
                                    style={{ width: 500 }}
                                  />
                                  {formData[`${p.id}_is_active`] && (
                                    <CheckboxInput
                                      style={{
                                        fontStyle: 'italic',
                                        marginLeft: 10,
                                      }}
                                      className={classes.CheckboxInput}
                                      customOnChange={customOnChange}
                                      label={`Require auths for ${p.payers_name}`}
                                      value={`${p.id}_auths_required`}
                                      name={`${p.id}_auths_required`}
                                      size='small'
                                    />
                                  )}
                                </div>
                              </p>
                            ))}
                          </div>
                        ) : null}
                      </Fragment>
                    )}
                    {(values.payer_id || values.cases_payers_id) &&
                      !record.is_primary &&
                      isActive &&
                      (caseId || values.case_id) &&
                      isPayerChange && (
                        <div className={clsx(classes.inputContainerWrap)}>
                          <FormTextField
                            name='effective_date'
                            label='Effective date'
                            customOnChange={customOnChange}
                            type='date'
                            validate={composeValidators([
                              required(),
                              validateDate(),
                            ])}
                            required
                            style={{ marginTop: 25 }}
                          />
                          <div style={{ flex: '1 0 160px' }} />
                        </div>
                      )}
                    {(isPayerChange || newSecondaryWithCopay) &&
                      !addAuth &&
                      (values.payer_id === 0 ||
                        values.payer_id > HIGHEST_NO_AUTH_PAYER_ID) && (
                        <div className={clsx(classes.inputContainerWrap)}>
                          <SelectComponent
                            customOnChange={customOnChange}
                            label='Case Type'
                            validate={required()}
                            required
                            name='is_mltc'
                            choices={caseTypes}
                          />
                          <ReferenceInput
                            reference='facility-users/list'
                            customOnChange={customOnChange}
                            name='user_id'
                            label='Case manager'
                            validate={required()}
                            required
                            options={{
                              filter: { create: !!!isEdit },
                              sort: { field: 'last_name', order: 'ASC' },
                            }}
                            style={{ marginRight: 0 }}
                            shouldFetchMore
                          />
                        </div>
                      )}
                    {(isPayerChange || newSecondaryWithCopay) &&
                      (values.payer_id === 0 ||
                        values.payer_id > HIGHEST_NO_AUTH_PAYER_ID) && (
                        <div className={classes.secondaryActionContainer}>
                          {!addAuth ? (
                            <DialogContentText
                              color='primary'
                              onClick={openAuth}
                              className={classes.secondaryAction}
                            >
                              <AddIcon /> Add auth
                            </DialogContentText>
                          ) : (
                            <DialogContentText
                              color='primary'
                              onClick={() => {
                                removeAuthFields('primary');
                                setAddAuth(false);
                              }}
                              className={classes.secondaryAction}
                            >
                              <RemoveIcon style={{ marginRight: 5 }} /> Remove
                              auth
                            </DialogContentText>
                          )}
                        </div>
                      )}
                    {addAuth &&
                      Array.isArray(values.update_type) &&
                      Array.isArray(values.update_weekday) && (
                        <AuthFields
                          isEdit={isEdit}
                          customOnChange={authOnChange}
                          caseId={caseId}
                          values={values}
                          record={record}
                          formData={formData}
                          setFormData={setFormData}
                          subLevelChoices={subLevelChoices}
                          ot_option={ot_option}
                          pt_option={pt_option}
                          speech_option={speech_option}
                          setOtOption={setOtOption}
                          setPtOption={setPtOption}
                          setSpeechOption={setSpeechOption}
                          isPartB={false}
                        />
                      )}
                    {values.change_type === 'changeMltc' && (
                      <FormTextField
                        name='mltc_name'
                        label='Payer name'
                        fullWidth
                        customOnChange={customOnChange}
                        validate={required()}
                        required
                      />
                    )}
                    {isPayerChange && (
                      <FormTextField
                        name='reason'
                        label='Payer change reason'
                        multiline
                        fullWidth
                        customOnChange={customOnChange}
                        validate={composeValidators([
                          required(),
                          minReasonLength(),
                        ])}
                        required
                      />
                    )}
                    {isPayerChange && (
                      <Fragment>
                        <div className={clsx(classes.inputContainerWrap)}>
                          <RadioInput
                            name='is_denial'
                            className={classes.radio}
                            customOnChange={customOnChange}
                            required
                            validate={required()}
                            value={parseInt(formData.is_denial)}
                            row
                            label='Is there a denial?'
                            radios={[
                              { label: 'Yes', value: 1 },
                              { label: 'No', value: 0 },
                            ]}
                          />{' '}
                        </div>
                        <div className={clsx(classes.inputContainerWrap)}>
                          {(values.is_denial === 1 ||
                            values.is_denial === '1') && (
                            <Fragment>
                              {' '}
                              <FormTextField
                                name='denial_lcd'
                                validate={composeValidators([
                                  validateDate(),
                                  ...(values.is_denial + 1 === 2
                                    ? [required()]
                                    : []),
                                ])}
                                required={!!+values.is_denial}
                                customOnChange={customOnChange}
                                type='date'
                                label='Denial LCD'
                                style={{ marginLeft: 0 }}
                              />
                              <div style={{ flex: '1 0 160px' }} />
                            </Fragment>
                          )}
                        </div>
                      </Fragment>
                    )}
                    {!isEdit &&
                      values.change_type !== 'changePriority' &&
                      values.change_type !== 'changeMltc' && (
                        <div className={classes.insuranceTitle}>
                          Attachments
                          <FileInput
                            title='title'
                            name='attachments'
                            setFormData={setFormData}
                            formData={formData}
                            classes={{ dropZone: classes.dropZone }}
                          />
                        </div>
                      )}
                  </Fragment>
                )}
              </DialogContent>
              <DialogActions
                className={classes.padding16}
                style={{ paddingBottom: 16 }}
              >
                {(meta.origin ||
                  (values.change_type &&
                    (values.change_type !== 'changePriority' ||
                      hasSecondaryPayers))) && (
                  <SaveButton
                    className={classes.saveButton}
                    disabled={submitting}
                    type='submit'
                  />
                )}
              </DialogActions>
            </form>
          );
        }}
      />
    </Fragment>
  );
};

function getFieldIntValue(field) {
  const intValue = parseInt(field);
  return typeof intValue === 'number' && intValue > 0 ? parseInt(field) : 0;
}

async function fetchResource(url, params) {
  const response = await authGet(params ? [`/${url}`, params] : url);
  if (response.error) return 'error';
  return response.data;
}

function getPrimaryPayer(selectedPayer, otherPayers) {
  if (!selectedPayer.is_primary) {
    return otherPayers.filter(payer => payer.is_primary)[0];
  }
}

function getRepProps(record) {
  const {
    rep_phone,
    rep_fax,
    rep_name,
    rep_email,
    phone_ext,
    rep_comments,
    comments,
  } = record;
  return {
    rep_name,
    rep_phone: normalizePhone(rep_phone),
    rep_fax: normalizePhone(rep_fax),
    rep_email,
    phone_ext,
    rep_comments: rep_comments || comments,
  };
}

function sanitizeRep(data) {
  const {
    id,
    phone,
    fax,
    name,
    email,
    comments,
    payer_facility_id,
    ...rest
  } = data;
  return {
    rep_name: name,
    rep_phone: normalizePhone(phone),
    rep_fax: normalizePhone(fax),
    rep_email: email,
    rep_comments: comments,
    ...rest,
  };
}

function ToggleInsuranceNetwork({ state, setState, name }) {
  const handleChange = event => {
    setState(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={state}
          onChange={handleChange}
          name={name}
          color='primary'
        />
      }
      label='In network'
    />
  );
}
