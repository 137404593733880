import { fetchUtils } from 'react-admin';

import jsonServerProvider from './ra-data-json-server';

import { authToken } from './authProvider';

import { cacheDataProviderProxy } from 'react-admin';
import { API_URL } from "../utils/config"

export * from './authRoles';
export * from './authProvider';
export * from './AuthRequest';
export * from './file';
export { useLogin } from './useLogin';

const httpClient = (url, options = {}) => {
  const token = authToken();
  if (token) {
    const bearerToken = `Bearer ${token}`;
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Authorization', bearerToken);
  }
  return fetchUtils.fetchJson(url, options);
};
const jsonDataProvider = jsonServerProvider(API_URL, httpClient);
export const dataProvider = cacheDataProviderProxy(jsonDataProvider);
