import React, { useState, Fragment } from 'react';
import { stringify } from 'query-string';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  List,
  TextField,
  TopToolbar,
  Filter,
  DateInput,
  FunctionField,
  Pagination,
} from 'react-admin';
import { format, startOfWeek, addWeeks } from 'date-fns';
import { ToggleButton, Datagrid } from '../../components/common';
import { weekDaysObj } from '../../utils';
import { DOWNLOAD_URL } from '../../utils/config';
import { useStyles } from './reports.styles';
import { DownloadIcon, CustomButton } from '../../design';
import { Form } from 'react-final-form';
import { SelectComponent } from '../../components/common/modals/modalForms';

const ListActions = ({
  displayedFilters,
  filters,
  filterValues = {},
  permanentFilter = {},
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  setDivision,
  division,
  showAllCases,
  classes,
}) => {
  const token = localStorage.getItem('token');
  const facilityId = useSelector(state => state.facility.id);
  const [selectOpen, setSelectOpen] = useState(false);
  const handleChange = e => {
    const {
      target: { value },
    } = e;
    const doc_type = value;
    window.open(
      `${DOWNLOAD_URL}?${stringify({
        _facilityId: facilityId,
        token,
        report: 'updates-due',
        _start: 0,
        _end: 1000,
        division,
        showAllCases,
        doc_type,
        date_run: format(Date.now(), 'M/dd/yyyy h:mm a'),
        ...filterValues,
      })}`,
      '_blank',
    );
    setSelectOpen(false);
  };
  return (
    <TopToolbar
      className={clsx(
        classes.listActionWrapper,
        classes.flexWrap,
        classes.tabsActionWrapper,
      )}
    >
      {' '}
      <div style={{ flex: '1 0 16px' }} />
      <CustomButton
        Icon={DownloadIcon}
        color='#1061A0'
        backgroundColor='#EFF4FB'
        label='Export'
        type='button'
        variant='text'
        size='small'
        disabled={total === 0}
        onClick={() => setSelectOpen(true)}
      />
      {selectOpen && (
        <Form
          onSubmit={() => {}}
          render={() => (
            <SelectComponent
              classes={{
                notchedOutline: classes.notchedOutline,
              }}
              style={{ maxWidth: 120, height: -2 }}
              label='Export as'
              customOnChange={handleChange}
              name='doc_type'
              choices={[
                { id: 'pdf', name: 'PDF' },
                { id: 'docx', name: 'Word Doc' },
              ]}
            />
          )}
        />
      )}
    </TopToolbar>
  );
};

const FiltersView = ({ classes, ...props }) => {
  return (
    <Filter {...props} className={classes.filterContainer}>
      <DateInput
        label='Week of'
        source='start_date'
        alwaysOn
        options={{ variant: 'standard', required: true }}
        InputProps={{
          disableUnderline: true,
        }}
        style={{ color: '#5C738E' }}
      />
    </Filter>
  );
};

export const UpdatesReportList = ({ staticContext, ..._props }) => {
  const props = getResourceProps(_props);
  const classes = useStyles();
  const [division, setDivision] = useState('subacute');

  return (
    <Fragment>
      <div style={{ display: 'flex' }}>
        <div className={classes.title} style={{ paddingTop: 30 }}>
          Updates schedule report
        </div>
        <div style={{ padding: 30 }}>
          <ToggleButton
            setDivision={setDivision}
            className={classes.toggleWrapper}
            showBoth={false}
          />
        </div>
      </div>
      <List
        empty={false}
        {...props}
        bulkActionButtons={false}
        actions={
          <ListActions
            setDivision={setDivision}
            division={division}
            classes={classes}
          />
        }
        filter={{ division }}
        exporter={false}
        filters={<FiltersView classes={classes} />}
        filterDefaultValues={{
          start_date: format(
            startOfWeek(addWeeks(new Date(), 1)),
            'yyyy-MM-dd',
          ),
        }}
        pagination={
          <Pagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{
              display: 'flex',
            }}
          />
        }
      >
        <Datagrid>
          <TextField
            source='full_name'
            label='Resident name'
            sortBy='last_name'
          />
          <TextField source='payer' label='Insurance' />
          <TextField source='lob' label='LOB' />
          <TextField source='insurance_auth_number' label='Auth number' />
          <TextField source='next_update' label='Next update' />
          <TextField source='update_frequency' label='Update frequency' />
          <FunctionField
            sortable={false}
            source='update_type'
            label='Update type'
            render={record =>
              record.update_type && record.update_type.join(', ')
            }
          />
          <FunctionField
            source='update_weekday'
            label='Days of week'
            sortable={false}
            render={record => {
              const { update_weekday } = record;
              return (
                Array.isArray(update_weekday) &&
                update_weekday.map(u => weekDaysObj[u + '']).join(', ')
              );
            }}
          />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export const updatesDueResource = {
  name: 'updates-report',
  list: UpdatesReportList,
};

function getResourceProps(props) {
  const basePath = '/updates-schedule-report';
  const resource = props.resource
    ? { resource: props.resource }
    : {
        resource: 'reports/updates-report',
        hasList: true,
        hasEdit: false,
        hasShow: !!updatesDueResource.show,
        hasCreate: !!updatesDueResource.create,
      };
  return { ...props, basePath, ...resource };
}
